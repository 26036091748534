import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";

// Основные стили для селекта
const CustomSelectContainer = tw.div`relative w-full mt-5`;
const SelectedOption = tw.button`w-full py-3 px-4 font-medium bg-white text-left border-b-2 border-gray-300 focus:outline-none focus:border-primary-500 cursor-pointer flex justify-between items-center`;
const OptionsList = styled.ul`
  ${tw`absolute font-medium left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-2 z-10`}
  max-height: 200px;
  overflow-y: auto;
`;
const Option = tw.li`px-4 py-2 hover:bg-gray-200 text-left cursor-pointer`;

// Кастомный компонент селекта
const CustomSelect = ({ options, onSelect, defaultValue, setError }) => {
  const [isOpen, setIsOpen] = useState(false); // Состояние для открытия/закрытия списка опций
  const [selectedValue, setSelectedValue] = useState(defaultValue.label); // Состояние для выбранного значения

  // Функция для переключения видимости списка
  const toggleOptions = () => setIsOpen(!isOpen);

  // Функция для выбора опции
  const handleOptionClick = (option) => {
    setSelectedValue(option.label); // Устанавливаем выбранное значение (отображаем метку)
    setIsOpen(false); // Закрываем список
    setError(false);
    onSelect(option.value); // Передаем выбранное значение наверх
  };

  return (
    <CustomSelectContainer>
      <SelectedOption type="button" onClick={toggleOptions}>
        {selectedValue || "Виберіть значення"}
        <span>{isOpen ? "▲" : "▼"}</span>
      </SelectedOption>
      {isOpen && (
        <OptionsList>
          {options.map((option, index) => (
            <Option key={index} onClick={() => handleOptionClick(option)}>
              {option.label}
            </Option>
          ))}
        </OptionsList>
      )}
    </CustomSelectContainer>
  );
};

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex justify-center py-4 md:py-6`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12  md:mt-0`,
  props.textOnLeft ? tw`mr-2 md:order-first` : tw`ml-2 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const Form = tw.form`mt-4 text-sm flex flex-col max-w-sm mx-auto`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 p-3 text-black focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-6`;

export default ({
  textOnLeft = true,
}) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState({ label: t('languageLabel'), value: "" });
  const [formdata, setFormData] = useState("");
  const handleLanguageSelect = (selectedValue) => {
    setSelectedLanguage({ value: selectedValue });
  };

  function generateShortId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0;i < 9;i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  const [promocode, setPromocode] = useState("");
  const [error, setError] = useState(false);
  const promos = ['UKRAINE', 'PURE2024', 'ALEKSANKIN', "DAU", "SB-CLUB", "BOARD"];
  const handlePromocodeChange = (e) => setPromocode(e.target.value.toUpperCase());

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedLanguage.value) {
      setError(true);
      return;
    }
    let productPrices = [Number(t("price"))]; // Цена продукта
    let productCount = 1; // Количество продукта

    let FinalPrice = Number(t("price"));

    const FinalAmount = promos.includes(promocode) ? FinalPrice * 0.9 : FinalPrice;

    const orderID = generateShortId();
    const formData = {
      merchantAccount: "psy_lica_center_com_ua1",
      apiVersion: "2",
      orderNo: `${selectedLanguage.value}|true|standart`,
      clientAccountId: "standart",
      merchantDomainName: "https://nannyok.stimul.ua",
      orderReference: orderID,
      orderDate: Date.now(),
      amount: FinalAmount,
      serviceUrl: "https://nannyapi.stimul.ua/wayforpay",
      returnUrl: `https://nannyok.stimul.ua/thank-you`,
      currency: t("currency"),
      productName: t("productName"),
      productCount: productCount,
      productPrice: productPrices.join(";"),
      clientEmail: formdata,
      merchantSignature: "",
    };

    // Генерация подписи
    const generateSignature = () => {
      const signatureString = `${formData.merchantAccount};${formData.merchantDomainName};${formData.orderReference};${formData.orderDate};${formData.amount};${formData.currency};${formData.productName};${formData.productCount};${formData.productPrice}`;
      return CryptoJS.HmacMD5(signatureString, "f2e86d80efebdf11fd71ef9bc2045d170d8b951f").toString(CryptoJS.enc.Hex);
    };

    formData.merchantSignature = generateSignature();

    // Создаем и отправляем форму программно
    const wayforpayForm = document.createElement('form');
    wayforpayForm.action = "https://secure.wayforpay.com/pay";
    wayforpayForm.method = "POST";

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = formData[key];
        wayforpayForm.appendChild(hiddenField);
      }
    }

    document.body.appendChild(wayforpayForm);
    localStorage.setItem('email', formdata);
    localStorage.setItem('orderReference', orderID);
    wayforpayForm.submit();
  };

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{t('heading')}</Heading>
            <Form onSubmit={handleSubmit}>
              <Input type="email" onChange={(e) => setFormData(e.target.value)} name="clientEmail" placeholder={t('emailPlaceholder')} required />

              <CustomSelect
                setError={setError}
                options={[
                  { label: t('languages.english'), value: "en" },
                  { label: t('languages.ukrainian'), value: "uk" },
                  { label: t('languages.russian'), value: "ru" }
                ]}
                onSelect={handleLanguageSelect}
                defaultValue={selectedLanguage}
                required
              />
              {error && <p style={{ color: "red" }}>{t('errorSelectLanguage')}</p>}
              <Input type="text" name="promocode" placeholder={t('promocodePlaceholder')} onChange={handlePromocodeChange} />

              <SubmitButton type="submit">{t('submitButtonText')}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
