import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { LogoLink } from "components/headers/light";
import VISA from "images/VISA.png";
import MASTERCARD from "images/MasterCard.png";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative -mb-8 px-8`;
const Content = tw.div`w-full mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-4 lg:mb-0 w-full lg:w-1/2`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const Divider = tw.div`my-8 border-b-2 border-gray-800`;

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoLink style={{ fontSize: "30px" }} href="/">
                Няня<span style={{ color: "rgb(72,187,120)" }}>ОК</span>
              </LogoLink>
            </LogoContainer>

            <SocialLinksContainer>
              <Link href="tel:+380633018030">
                {t('footer.supportService')}
              </Link>
            </SocialLinksContainer>
          </CompanyColumn>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <div>&copy; {t('footer.copyright')}</div>
          <div style={{ display: "flex", gap: "10px" }}>
            <SocialLinksContainer>
              <img style={{ maxWidth: "6rem" }} src={VISA} />
            </SocialLinksContainer>
            <SocialLinksContainer>
              <img style={{ maxWidth: "6rem" }} src={MASTERCARD} />
            </SocialLinksContainer>
          </div>
          <Link href='https://nannyok.stimul.ua/oferta'>{t('footer.offer')}</Link>
          <Link href='https://nannyok.stimul.ua/policy'>{t('footer.privacyPolicy')}</Link>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
