import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { stateToMarkdown } from "draft-js-export-markdown";
import tw from "twin.macro";
import styled from "styled-components";
import { FaTelegramPlane, FaViber, FaWhatsapp, FaRegCopy } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Container = tw.div`p-4 flex flex-col items-center justify-center`;
const Button = styled.button`
  ${tw`px-4 py-2 rounded-full flex items-center justify-center text-white cursor-pointer mx-2`}
  background-color: ${({ bgColor }) => bgColor || "#000"};
`;

const TextContainer = tw.div`mt-4 p-4 border border-gray-300 rounded-lg bg-white w-full max-w-4xl`;

const EditorWrapper = tw.div`p-4 border-2 border-blue-500 rounded-lg bg-gray-100 shadow-lg w-full max-w-sm md:max-w-4xl`;
const EditorContainer = styled.div`
  ${tw`min-h-[300px] p-4 bg-white rounded-md border-2 border-gray-300`}
  font-size: 18px;
  line-height: 1.6;
`;

const LinkButton = styled.a`
  ${tw` px-4 py-2 rounded-full flex items-center justify-center text-white cursor-pointer mx-2`}
  background-color: ${({ bgColor }) => bgColor || "#000"};
`;

const getMarkdownFromEditor = (editorState) => {
   const contentState = editorState.getCurrentContent();
   return stateToMarkdown(contentState);
};

const RichTextEditor = ({ orderReference }) => {
   const localStorageKey = "richTextContent";
   const { t, i18n } = useTranslation();

   // Загружаем данные из localStorage или создаем пустой текст
   const loadContentFromStorage = () => {
      const savedData = localStorage.getItem(localStorageKey);
      if (savedData) {
         const contentState = convertFromRaw(JSON.parse(savedData));
         return EditorState.createWithContent(contentState);
      } else {
         const defaultText = t('standartMessage', {
            orderReference: orderReference || 'REPLACEMENT_ORDER_REFERENCE',
         });
         const contentState = ContentState.createFromText(defaultText);
         return EditorState.createWithContent(contentState);
      }
   };

   const [editorState, setEditorState] = useState(() => loadContentFromStorage());
   const [copied, setCopied] = useState(false);

   useEffect(() => {
      // Сохранение данных в localStorage при изменении редактора
      const contentState = editorState.getCurrentContent();
      localStorage.setItem(localStorageKey, JSON.stringify(convertToRaw(contentState)));
   }, [editorState]);

   useEffect(() => {
      // Обновляем текст в редакторе при изменении orderReference или языка
      const defaultText = t('standartMessage', {
         orderReference: orderReference || 'REPLACEMENT_ORDER_REFERENCE',
      });
      const contentState = ContentState.createFromText(defaultText);
      setEditorState(EditorState.createWithContent(contentState));
   }, [orderReference, i18n.language]); // Добавили зависимость от языка

   const handleEditorChange = (newState) => {
      setEditorState(newState);
      setCopied(false); // Убираем сообщение о копировании при изменении текста
   };

   const textToSend = getMarkdownFromEditor(editorState);

   return (
      <Container>
         <EditorWrapper>
            <EditorContainer>
               <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  toolbar={{
                     options: ["inline"],
                     inline: {
                        options: ["bold", "italic", "underline"],
                     },
                  }}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
               />
            </EditorContainer>
         </EditorWrapper>

         <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "20px 0", gap: "5px" }}>
            <LinkButton
               href={`https://t.me/share/url?url=https://nannytest.stimul.ua/${orderReference}&text=${encodeURIComponent(textToSend)}`}
               target="_blank"
               bgColor="#0088cc"
               style={{ width: "150px" }}
            >
               <FaTelegramPlane size={24} /> <span tw="ml-2">Telegram</span>
            </LinkButton>
            <LinkButton
               href={`viber://forward?text=${encodeURIComponent(textToSend)}`}
               target="_blank"
               bgColor="#665ca7"
               style={{ width: "150px" }}
            >
               <FaViber size={24} /> <span tw="ml-2">Viber</span>
            </LinkButton>
            <LinkButton
               href={`whatsapp://send?text=${encodeURIComponent(textToSend)}`}
               target="_blank"
               style={{ width: "150px" }}
               bgColor="#25D366"
            >
               <FaWhatsapp size={24} /> <span tw="ml-2">WhatsApp</span>
            </LinkButton>
            <CopyToClipboard text={textToSend} onCopy={() => setCopied(true)}>
               <Button style={{ width: "150px" }} bgColor={copied ? "#cbd5e0" : "#50585A"}>
                  <FaRegCopy size={24} style={{ marginRight: "5px" }} />{" "}
                  <span tw="ml-2 ">{copied ? t('copied') : t("copy")}</span>
               </Button>
            </CopyToClipboard>
         </div>
      </Container>
   );
};

export default RichTextEditor;
