import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { useTranslation } from 'react-i18next';

const StyledHeader = styled(Header)`
  ${tw`pt-4 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-4 bg-center bg-cover`});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-25`;

const HeroContainer = tw.div`z-20 relative max-w-screen-2xl mx-auto`;
const TwoColumn = tw.div`pt-2 md:pt-24 pb-4 flex gap-6 md:gap-32 items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-2/5 mt-2 md:mt-16 lg:mt-0 lg:pl-0`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-primary-500 text-gray-100 font-bold rounded shadow transition duration-300 hocus:bg-gray-100 hocus:text-primary-500 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container tw=" -mx-8 -mt-4 pt-4 px-8">
      <OpacityOverlay />
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        <TwoColumn>
          <LeftColumn style={{ order: 2 }}>
            <Notification style={{ color: "rgb(74 85 104)" }}>{t('hero.notification')}</Notification>
            <Heading style={{ color: "rgb(74 85 104)" }}>
              <span>{t('hero.headingLine1')}</span>
              <br />
              <SlantedBackground>{t('hero.headingLine2')}</SlantedBackground>
            </Heading>
            <PrimaryAction id='buy' onClick={onClick}>{t('hero.buttonText')}</PrimaryAction>
          </LeftColumn>
          <RightColumn style={{ order: 1 }}>
            <StyledResponsiveVideoEmbed style={{ padding: 0 }}
              url="https://www.youtube.com/embed/rnV9goO7h9Y?si=CiG9g3azV78PYCUD"
              background="transparent"
            />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
