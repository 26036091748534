import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Overlay = styled.div`
  ${tw`fixed left-0 bottom-0 p-4 bg-gray-900 bg-opacity-50 flex justify-center items-end`}
  z-index: 999;
`;

const WidgetContainer = styled.div`
  ${tw`bg-white p-6 rounded-lg shadow-lg max-w-lg w-full`}
`;

const Title = styled.h2`
  ${tw`text-xl font-bold mb-4`}
`;

const Description = styled.p`
  ${tw`mb-4 text-sm text-gray-600`}
`;

const Button = styled.button`
  ${tw`px-4 py-2 text-white rounded-lg cursor-pointer`}
  background-color: ${({ bgColor }) => bgColor || "#50585A"};
  margin-right: 10px; /* Добавлен отступ между кнопками */
`;

const PrivacyLink = styled.a`
  ${tw`text-blue-500 underline cursor-pointer`}
`;

const ConsentWidget = () => {
    const { t } = useTranslation(); // Используем i18next для перевода
    const [showWidget, setShowWidget] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("userConsent");
        if (!consent) {
            setShowWidget(true);
        }
    }, []);

    const handleConsent = (consent) => {
        localStorage.setItem("userConsent", consent ? "true" : "false");
        setShowWidget(false);
    };

    if (!showWidget) return null;

    return (
        <Overlay>
            <WidgetContainer>

                <Description>
                    {t('consentWidget.description')}{" "}
                    <PrivacyLink href="/privacy-policy" target="_blank">
                        {t('consentWidget.privacyPolicy')}
                    </PrivacyLink>.
                </Description>
                <div tw="flex justify-between mt-4">
                    <Button bgColor="#25D366" onClick={() => handleConsent(true)}>
                        {t('consentWidget.agreeButton')}
                    </Button>
                    <Button bgColor="#FF6B6B" onClick={() => handleConsent(false)} style={{ marginLeft: "10px" }}>
                        {t('consentWidget.declineButton')}
                    </Button>
                </div>
            </WidgetContainer>
        </Overlay>
    );
};

export default ConsentWidget;
