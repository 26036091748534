import React from "react";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative bg-gray-100 py-8 px-4`;
const Content = tw.div`max-w-screen-lg mx-auto p-8 bg-white shadow-lg rounded-lg`;
const Heading = tw.h1`text-4xl font-bold text-center text-primary-500 mb-8`;
const Section = tw.div`mb-6`;
const SubHeading = tw.h2`text-2xl font-semibold text-gray-800 mb-4`;
const Text = tw.p`text-gray-700 leading-loose mb-4`;

const PrivacyPolicyPage = () => {
   const { t } = useTranslation();

   return (
      <Container>
         <Content>
            <Heading>{t('privacyPolicy.heading')}</Heading>

            <Section>
               <SubHeading>{t('privacyPolicy.company')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section1Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section1Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section1Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section2Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section2Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section3Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section3Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section4Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section4Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section5Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section5Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section6Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section6Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section7Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section7Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section8Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section8Text')}
               </Text>
            </Section>

            <Section>
               <SubHeading>{t('privacyPolicy.section12Heading')}</SubHeading>
               <Text>
                  {t('privacyPolicy.section12Text')}
               </Text>
            </Section>

         </Content>
      </Container>
   );
};

export default PrivacyPolicyPage;
