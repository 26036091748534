import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import RichTextEditor from "components/forms/Copytext";
import i18next from "i18next";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-2xl flex-col justify-between items-center py-8 lg:py-8 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug text-xl sm:text-2xl`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-2xl mx-auto lg:mx-0`;

export default () => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const [orderReference, setOrderReference] = useState(localStorage.getItem('orderReference'));

  useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    return () => {
      document.body.removeChild(iframe);
    };
  }, []);
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };
  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-4 -mt-4 pt-4 px-4">
        <Content2Xl>
          <NavRow>
            <LogoLink style={{ fontSize: "30px" }} href="/">
              Няня<span style={{ color: "rgb(72,187,120)" }}>ОК</span>
            </LogoLink>
            <div>
              <button
                onClick={() => changeLanguage('uk')}
                style={{
                  marginRight: '10px',
                  fontWeight: i18next.language === 'uk' ? 'bold' : 'normal',
                }}
              >
                {t('hero.languages.ukrainian')}
              </button>
              <button
                onClick={() => changeLanguage('en')}
                style={{
                  fontWeight: i18next.language === 'en' ? 'bold' : 'normal',
                }}
              >
                {t('hero.languages.english')}
              </button>
            </div>
          </NavRow>
          <HeroRow>
            <Heading style={{ fontSize: "2.5rem" }}>{t('sendMessageToNanny')}</Heading>
            <RichTextEditor orderReference={orderReference} />
            <Description tw="mt-4">
              {t('messageToNannyDuplicated')}
              <span style={{ fontWeight: "700" }}>{localStorage.getItem('email')}</span>
            </Description>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
