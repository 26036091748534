import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from "components/footers/FiveColumnDark.js";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as DownloadIcon } from "feather-icons/dist/icons/download.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import heroScreenshotImageSrc from "images/demo/Няня-в-Праге.jpg";
import PDF from "images/demo/nannyok_zvit.pdf";
import PDFENG from "images/demo/nanny's_report.pdf";
import useInView from "helpers/useInView";
import ReactModalAdapter from "helpers/ReactModalAdapter";
import i18next from "i18next";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 backdrop-blur-md`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto  md:mt-[10vh] max-w-screen-lg md:max-h-[80vh] p-3 absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const SecondaryButton = tw.a`mt-6 sm:mt-12 justify-center sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900 px-8 py-3 inline-flex items-center`;

const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

export default ({
  primaryButtonUrl = "#landingPageDemos",
  primaryButtonText,
  secondaryButtonUrl = "#componentDemos",
  secondaryButtonText,
  buttonRoundedCss = "",
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  littleheading,
  heading,
  description
}) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const currentLanguage = i18next.language;
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const fileUrl = currentLanguage === 'en' ? PDFENG : PDF;

  const features = t('hero.features', { returnObjects: true });
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };
  return (
    <AnimationRevealPage>
      <Container tw=" bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow tw='md:mt-12'>
            <LogoLink style={{ fontSize: "30px" }} href="/">
              Няня<span style={{ color: "rgb(72,187,120)" }}>ОК</span>
            </LogoLink>
            <div>
              <button
                onClick={() => changeLanguage('uk')}
                style={{
                  marginRight: '10px',
                  fontWeight: i18next.language === 'uk' ? 'bold' : 'normal',
                }}
              >
                {t('hero.languages.ukrainian')}
              </button>
              <button
                onClick={() => changeLanguage('en')}
                style={{
                  fontWeight: i18next.language === 'en' ? 'bold' : 'normal',
                }}
              >
                {t('hero.languages.english')}
              </button>
            </div>
          </NavRow>
          <HeroRow tw='min-h-[65vh] pb-8 pt-4'>
            <TextColumn>
              <Description tw='mb-6 font-bold'>{t('hero.littleheading')}</Description>
              <Heading tw='text-3xl' as="h1">{t('hero.heading')}</Heading>
              <Description>{t('hero.description')}</Description>
              <Description tw='font-bold'>{t('hero.priceInfo')}</Description>
              <Actions>
                <PrimaryButton id='buy' onClick={toggleModal}>
                  {t('hero.primaryButtonText')}
                </PrimaryButton>

                <SecondaryButton id='pdf' href={fileUrl} download="nannyok_report.pdf" style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                  <DownloadIcon /> {t('hero.secondaryButtonText')}
                </SecondaryButton>
              </Actions>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn tw='mt-8 hidden md:block' >
              <ImageContainer>
                <Image src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
          </HeroRow>
          <Hero onClick={() => toggleModal()} />

        </Content2Xl>
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton tw='z-10' onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <TwoColContactUsFullForm />
          </div>
        </StyledModal>
      </Container >
    </AnimationRevealPage >
  );
};
